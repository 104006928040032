export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  MCUVersion: string;
  obdTimestamp: string;
  AUXVoltageGraph: (number | null)[];
  AUXAmpsGraph: (number | null)[];
  RPMGraph: (number | null)[];
  LoadGraph: number[];
  lastAUXVoltage: number;
  lastOEMVoltage: number;
  lastAUXAmps: number | null;
  totalLoad: number;
  totalEngineRuntime: number;
  milesDriven: number | null;
  odometer: number;
  fuelLevel: number;
  dates: string[];
  lastDate: Date;
  lastMPH: number;
  COTwoProduced: number;
  [key: string]: any;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
